const APPERSION = "1.0.3";
const APPHASH = "#ba2442118d986f674494cb3c03bccdc8425fdc68";

export const getApplicationVersion = () => {
  /*eslint-disable no-useless-concat*/
  if (APPERSION === "%APPLICATION" + "_" + "VERSION%") {
    return "dev-hot-reload";
  } else {
    return APPERSION;
  }
};
export const getApplicationHash = () => {
  if (APPHASH === "%APPLICATION" + "_" + "HASH%") {
    return "#dev-hot-reload";
  } else {
    return APPHASH;
  }
};
