import React, { useEffect, useState } from "react";
import { MappingConstants } from "react-cismap";
import TopicMapContextProvider from "react-cismap/contexts/TopicMapContextProvider";
import { md5FetchJSON } from "react-cismap/tools/fetching";
import HeavyRainHazardMap from "@cismet-dev/react-cismap-rainhazardmaps/HeavyRainHazardMap";
import GenericModalApplicationMenu from "react-cismap/topicmaps/menu/ModalApplicationMenu";
import Datengrundlage from "./help/Help10Datengrundlage";
import Karteninhalt from "./help/Help20Karteninhalt";
import InKartePositionieren from "./help/Help30InKartePositionieren";
import MeinStandort from "./help/Help40MeinStandort";
import WerteAbfragen from "./help/Help50WasserstandAbfragen";
import SimulierteSzenarien from "./help/Help60SimulierteSzenarien";
import Aussagekraft from "./help/Help70AussagekraftDerSimulationen";
import ModellfehlerMelden from "@cismet-dev/react-cismap-rainhazardmaps/components/customizablehelp/Help80ModellfehlerMelden";
import Haftungsausschluss from "@cismet-dev/react-cismap-rainhazardmaps/components/customizablehelp/Help90Haftungsausschluss";
import Kontakt from "./help/Help98Kontakt";
import Footer from "@cismet-dev/react-cismap-rainhazardmaps/components/customizablehelp/Help99Footer";
import paderbornConfig from "./config";
import { getApplicationVersion } from "./version";

function App() {
  const appKey = "cismetRainhazardMap.Paderborn";
  const email = "starkregen@paderborn.de";
  const [gazData, setGazData] = useState([]);

  const getGazData = async (setGazData, url) => {
    const prefix = "GazDataForStarkregengefahrenkarteByCismet";
    const data = await md5FetchJSON(prefix, url);

    setGazData(data);
  };
  useEffect(() => {
    getGazData(setGazData, "/data/adressen_paderborn.json");
  }, []);
  return (
    <TopicMapContextProvider
      appKey={appKey + ".Paderborn"}
      referenceSystem={MappingConstants.crs3857}
      referenceSystemDefinition={MappingConstants.proj4crs3857def}
      baseLayerConf={paderbornConfig.overridingBaseLayerConf}
      infoBoxPixelWidth={370}
    >
      <HeavyRainHazardMap
        appMenu={
          <GenericModalApplicationMenu
            menuIntroduction={
              <span>
                Bitte wählen Sie eine der folgenden farbigen Schaltflächen, um sich weitere
                Informationen zu dem entsprechenden Thema anzeigen zu lassen:
              </span>
            }
            menuIcon='info'
            menuTitle='Kompaktanleitung und Hintergrundinformationen'
            menuSections={[
              <Datengrundlage key='Datengrundlage' />,
              <Karteninhalt key='Karteninhalt' simulationsklammer='Stärke 7, Stärke 10' />,
              <InKartePositionieren key='InKartePositionieren' />,
              <MeinStandort key='MeinStandort' />,
              <WerteAbfragen key='WerteAbfragen' />,
              <SimulierteSzenarien key='SimulierteSzenarien' />,
              <Aussagekraft key='Aussagekraft' />,
              <ModellfehlerMelden key='ModellfehlerMelden' email={email} />,
              <Haftungsausschluss
                appName='Starkregengefahrenkarte Paderborn'
                key='Haftungsausschluss'
              />,
              <Kontakt key='Kontakt' email={email} />,
            ]}
            menuFooter={
              <Footer
                appName='AIS Paderborn'
                version={getApplicationVersion()}
                logoUrl='/images/Signet_AIS_RZ.png'
                hintergrundkartenText='OSM light @ cismet | DOP © Geobasis NRW | Stadtkarte 2.0 © RVR |  DTK Sammeldienst © Geobasis NRW '
                taglineModelling={
                  <div>
                    <b>Modellierung und AIS Starkregenvorsorge</b> (Version 1.0 | 10/2021):{" "}
                    <a target='_paderborn' href='https://paderborn.de/'>
                      Stadt Paderborn
                    </a>{" "}
                    |{" "}
                    <a target='_pecher' href='https://www.pecher.de/'>
                      Dr. Pecher AG (Gelsenkirchen/Erkrath)
                    </a>
                  </div>
                }
              />
            }
          />
        }
        emailaddress={email}
        initialState={paderbornConfig.initialState}
        config={paderbornConfig.config}
        homeZoom={14}
        homeCenter={[51.71905, 8.75439]}
        modeSwitcherTitle='AIS Starkregenvorsorge Paderborn'
        documentTitle='Starkregengefahrenkarte Paderborn'
        gazData={gazData}
      />
    </TopicMapContextProvider>
  );
}

export default App;
